import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserProfileReadModel } from 'consistent-api-nvx-internal-sdk-dev';
import { catchError, firstValueFrom, Observable, shareReplay, throwError } from 'rxjs';

import { EnvironmentInterface } from '@/shared/environment.interface';
import { ENVIRONMENT } from '@/shared/environment.token';
import { ApiProviderService } from '@/shared/lib/services/api-provider.service';
import { UserProfile } from '@/shared/lib/types/user.type';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  httpClient = inject(HttpClient);
  sdk = inject(ApiProviderService).tenantSdk;
  environment: EnvironmentInterface = inject(ENVIRONMENT);

  private publicProfileById: { [key: string]: Observable<UserProfileReadModel> } = {};

  getCurrentUser = () => {
    return firstValueFrom(this.sdk.currentUser.currentUserGet({}));
  };

  getMyUserProfile = () => {
    return firstValueFrom(
      this.httpClient.get<{
        items: UserProfile[];
        skipped: number;
        total: number;
      }>(`${this.environment.eventSourceUrl}/read-models/my-user-profile`)
    );
  };

  updateMyProfile = (data: UserProfile) => {
    return firstValueFrom(this.httpClient.post(`${this.environment.eventSourceUrl}/commands/update-my-profile`, data));
  };

  getUserByid = (id: string) => {
    return firstValueFrom(this.sdk.userProfile.getUserProfile({ id }));
  };

  getPublicUserById = (id: string) => {
    return firstValueFrom(this.sdk.userProfile.getPublicUserProfile({ id }));
  };

  getPublicUserProfileById(id: string): Observable<UserProfileReadModel> {
    if (this.publicProfileById[id]) {
      return this.publicProfileById[id];
    }
    const publicUserProfile$ = new Observable<UserProfileReadModel>((observer) => {
      this.getPublicUserById(id)
        .then((profile) => {
          observer.next(profile);
          observer.complete();
        })
        .catch((err) => observer.error(err));
    }).pipe(
      shareReplay(1),
      catchError((err) => throwError(() => new Error(err)))
    );
    this.publicProfileById[id] = publicUserProfile$;
    return publicUserProfile$;
  }
}

type OrganizationDetails = { organizationId: string; organizationName: string };

type LocationDetails = {
  locationId: string;
  locationName: string;
  locationType: string;
  addressId: string | null;
};

type AddressDetails = { locationAddress: string; agentTMSReference: string; addressId: string };

type ContactPersonDetails = {
  contactPersonId: string;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
};

type FoundContactPersonDetails = OrganizationDetails & LocationDetails & AddressDetails & ContactPersonDetails;

export type UserContactPerson =
  | ({ type: 'found'; tenantId: string | null } & FoundContactPersonDetails)
  | { type: 'missing'; tenantId: string | null; message: string }
  | { type: 'not loaded' };
